<template>
    <div class="mb-5 mb-xl-8 card">
        <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label fw-bolder fs-3 mb-1">{{ $t("pages.roadmap.title") }}</span>
                <span class="text-muted mt-1 fw-bold fs-7">{{ $t("pages.roadmap.themeManagement") }}</span>
            </h3>
            <div class="align-items-end flex-column">
                <a href="#" class="btn btn-primary btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_1">{{ $t("pages.roadmap.addTheme") }}</a>
            </div>
        </div>
        <div class="card-body py-3">
            <div class="table-responsive">
                <table class="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                    <thead>
                        <tr class="fw-bolder text-muted">
                            <th class="min-w-150px">{{ $t("pages.roadmap.themeName") }}</th>
                            <th class="min-w-100px text-end">{{ $t("pages.usersList.actions") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="category in categories" v-bind:key="category.pk">
                            <td>
                                <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{ category.label }}</a>
                            </td>
                            <td class="text-end">
                                <a
                                    @click="this.initEdit(category)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_1"
                                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                >
                                    <span class="svg-icon svg-icon-3"
                                        ><svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                opacity="0.3"
                                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                fill="black"
                                            ></path>
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                fill="black"
                                            ></path></svg></span
                                ></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("pages.roadmap.addEditTheme") }}</h5>

                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x"></span>
                    </div>
                    <!--end::Close-->
                </div>

                <div class="modal-body">
                    <Form id="kt_add_action_form" class="form" novalidate="novalidate">
                        <div class="row mb-6">
                            <!--begin::Label-->
                            <label class="col-lg-12 col-form-label required fw-bold fs-6">{{ $t("pages.roadmap.themeName") }}</label>
                            <!--end::Label-->

                            <!--begin::Col-->
                            <div class="col-lg-12">
                                <!--begin::Row-->
                                <div class="row">
                                    <!--begin::Col-->
                                    <div class="col-lg-12 fv-row">
                                        <Field
                                            type="text"
                                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                            :placeholder="$t('pages.roadmap.themeName')"
                                            name="categoryLabel"
                                            v-model="this.category.label"
                                        ></Field>
                                    </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                            </div>
                            <!--end::Col-->
                        </div>
                    </Form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
                    <button type="button" @click="addCategory" id="kt_account_profile_details_submit" ref="submitButton" class="btn btn-primary">
                        <span class="indicator-label"> {{ $t("general.add") }} </span>
                        <span class="indicator-progress">
                            {{ $t("general.pleaseWait") }}
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        <span class="indicator-validate">
                            {{ $t("general.done") }}
                            <span class="fas fa-check f-5 ms-3"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import apiRoadMap from "@/core/services/RoadMap";
import { Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-roadmapcategory",
    components: { Field },
    data: function () {
        return {
            category: {
                id: null,
                label: "",
            },
            categories: [],
        };
    },
    methods: {
        addCategory() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.category.id) {
                apiRoadMap.updateCategory(this.category.id, this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            } else {
                apiRoadMap.addCategory(this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            }
        },
        getCategories() {
            apiRoadMap.getRoadMapCategories().then((response) => {
                this.categories = response.data["roadmapscategories"];
            });
        },
        initEdit(category) {
            this.category = {
                id: category.pk,
                label: category.label,
            };
        },
        initAdd() {
            this.category = {
                id: null,
                label: "",
            };
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.roadmap.roadmapThemes"), []);
        const submitButton = ref<HTMLElement | null>(null);

        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.roadmapThemes"), []);
        },
    },
    created() {
        this.getCategories();
    },
});
</script>


import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import apiRoadMap from "@/core/services/RoadMap";
import { Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-roadmapcategory",
    components: { Field },
    data: function () {
        return {
            category: {
                id: null,
                label: "",
            },
            categories: [],
        };
    },
    methods: {
        addCategory() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.category.id) {
                apiRoadMap.updateCategory(this.category.id, this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            } else {
                apiRoadMap.addCategory(this.category).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            }
        },
        getCategories() {
            apiRoadMap.getRoadMapCategories().then((response) => {
                this.categories = response.data["roadmapscategories"];
            });
        },
        initEdit(category) {
            this.category = {
                id: category.pk,
                label: category.label,
            };
        },
        initAdd() {
            this.category = {
                id: null,
                label: "",
            };
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.roadmap.roadmapThemes"), []);
        const submitButton = ref<HTMLElement | null>(null);

        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs(this.t("pages.roadmap.roadmapThemes"), []);
        },
    },
    created() {
        this.getCategories();
    },
});
